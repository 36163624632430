import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Logo from "../components/logo"


const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "background-desktop-home.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Craft Coffee Roasters - Merchants Quay Dublin</title>
          </Helmet>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            <Navigation />
            <main
              style={{ backgroundColor: `rgba(0, 0, 0, 0.5)`, width: '33%', marginLeft: '30px', padding: '20px' }}
              className="main-body-content"
            >
              <Logo />
              <h1>Welcome to Craft Coffee Roasters</h1>
              <p>
                We like the simple things in life - great coffee and a quiet
                moment to appreciate your daily brew. Our coffee is hand roasted
                by master roaster John Gowan, who selects the highest quality
                arabica beans to create our unique coffee blends.
              </p>
            </main>
          </BackgroundImage>
        </Layout>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  height: 100vh;
  color: white;
`

export default StyledBackgroundSection
